.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.image-form-container {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  width: 350px;
  text-align: center;
}

.logo-image {
  width: 120px;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.input-container {
  margin-bottom: 15px;
  text-align: left;
}

.input-container label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.input-with-icon {
  display: flex;
  align-items: center;
  background: #f0f0f0;
  border-radius: 5px;
  padding: 8px;
}

.input-with-icon input {
  flex: 1;
  border: none;
  background: transparent;
  outline: none;
  font-size: 16px;
}

.input-icon {
  color: #666;
  margin-right: 10px;
}

.login-button {
  background: #007bff;
  color: white;
  font-size: 16px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background: #0056b3;
}