.table-container {
  max-width: 100%;
  overflow-x: auto;
}

.table-header {
  background-color: #e6e0e0;
}

.table-cell {
  font-weight: bold !important;
}

.no-data-found {
  text-align: center;
  font-weight: bold;
  color: red;
}

.filter-window{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.window-1{
  width: 100%;
}

.window-2{
  width: fit-content;
  display: flex;
  gap: 2px;
  justify-content: end;
  align-items: center;
}
.window-2 input{
  margin: 0;
}

.window-2 button{
  width: 100%;
}
