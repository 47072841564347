.client-window {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.window {
  width: 500px; /* Adjust modal width */
  background: white; /* Modal background */
  padding: 20px;
  border-radius: 10px;
  position: relative; /* Ensures absolute positioning inside */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.close {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid red;
  color: red;
  padding: 8px 12px; /* Increased padding for better clickability */
  border-radius: 8px; /* Increased border-radius for a smoother look */
  cursor: pointer;
  width: fit-content;
  justify-self: flex-end;
  align-self: flex-end;
  transition: background 0.3s; /* Smooth transition for hover effect */
}
.close:hover {
  background: rgba(255, 0, 0, 0.8); /* More noticeable hover effect */
  color: white; /* Change text color to improve visibility on hover */
}
